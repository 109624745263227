






































































































































import { Cliente, Contato, Funcionario, Porto } from "@/core/models/geral";
import { Proposta, PropostaEquipamento } from "@/core/models/operacional";
import { LocalOperacao } from "@/core/models/operacional/LocalOperacao";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { ClienteService, ContatoService, FuncionarioService, PortoService } from "@/core/services/geral";
import { PropostaService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaProposta extends PageBase {
    search: any = '';
    overlay: boolean = false;
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'numero', type:'number' },
        { text: 'Cliente', value: 'cliente.nome'},
        { text: 'Porto', value: 'porto.nome'},
        { text: 'Local Operação', value: 'localOperacao.nome'},
        { text: 'Contato', value: 'contato.complementar.nome'},
        { text: 'Responsável', value: 'responsavel.nome' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Link Proposta', value: 'link'},
    ];
    filtro: any = {
        numero: null,
        clienteId: null,
        contatoId: null,
        propostaId: null,
        portoId: null,
        localOperacaoId: null
    };

    tipoUsuario = EnumTipoUsuario;

    item = new Proposta();
    service = new PropostaService();

    clienteService = new ClienteService();
    clientes: Cliente[] = [];
    isClienteLoading: boolean = false;
    onSearchCliente: any = null;

    contatoService = new ContatoService();
    contatos: Contato[] = [];
    isContatoLoading: boolean = false;
    onSearchContato: any = null;

    funcionarioService = new FuncionarioService();
    responsaveis: Funcionario[] = [];
    isResponsavelLoading: boolean = false;
    onSearchResponsavel: any = null;

    portoService = new PortoService();
    portos: Porto[] = [];
    isPortoLoading: boolean = false;
    onSearchPorto: any = null;
    locaisOperacao: LocalOperacao[] = [];

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){

        if(isFiltro == true)
        {
            this.options.page = 1;
        }

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, "Cliente, Porto, Contato, Responsavel, LocalOperacao").then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    @Watch('onSearchContato')
    SearchContato (val: string) {
        if (this.isContatoLoading) return;
        if (this.filtro.contatoId) return;
        if (!val) return;
        this.isContatoLoading = true
        this.contatoService.AutoComplete(val).then(
            res => {
                this.contatos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isContatoLoading = false));
    }

    @Watch('onSearchResponsavel')
    SearchResponsavel (val: string) {
        if (this.isResponsavelLoading) return;
        if (this.filtro.responsavelId) return;
        if (!val) return;
        this.isResponsavelLoading = true
        this.funcionarioService.AutoComplete(val).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isResponsavelLoading = false));
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if (this.filtro.clienteId) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
            this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchPorto')
    SearchPorto (val: string) {
        if (this.isPortoLoading) return;
        if (this.filtro.portoId) return;
        if (!val) return;
        this.isPortoLoading = true
        this.portoService.AutoComplete(val, "LocaisOperacao").then(
        res => {
            this.portos = res.data;
        },
        err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isPortoLoading = false
        });
    }

    @Watch("filtro.portoId")
    WatchFiltroPorto(){
        if(this.filtro.portoId){
            this.locaisOperacao = this.portos.find(x => x.id == this.filtro.portoId)!.locaisOperacao.filter(x => x.ativo == true);
        }
        else{
            this.filtro.portoId = null;
            this.filtro.localOperacaoId = null;
            this.locaisOperacao = [];
        }
    }

    Duplicar(prop: Proposta){
        this.overlay = true;
        this.service.ObterPorId(prop.id, "Cliente, Contato, Responsavel, Ternos, Equipamentos.Equipamento.Categoria, Equipamentos.Periodo, Porto.LocaisOperacao.Periodos, Porto, LocalOperacao.Periodos, LocalOperacaoPeriodos.Periodo").then(
            res => {
                var retorno = new Proposta(res.data);
                //GERAL
                this.item = new Proposta();
                this.item.empresaId = this.app.empresaId;
                this.item.clienteId = retorno.clienteId;
                this.item.cliente = retorno.cliente;
                this.item.contatoId = retorno.contatoId;
                this.item.contato = retorno.contato;
                this.item.responsavelId = retorno.responsavelId;
                this.item.responsavel = retorno.responsavel;
                this.item.portoId = retorno.portoId;
                this.item.porto = retorno.porto;
                this.item.localOperacaoId = retorno.localOperacaoId;
                this.item.localOperacao = retorno.localOperacao;
                this.item.localOperacaoValor = retorno.localOperacaoValor;
                this.item.ternoValor = retorno.ternoValor;
                this.item.ternoPorcentagemNoturna = retorno.ternoPorcentagemNoturna;
                this.item.ternoPorcentagemFimSemanaFeriado = retorno.ternoPorcentagemFimSemanaFeriado;
                this.item.link = retorno.link;
                this.item.observacao = retorno.observacao;
                this.item.valorAtracacaoDesatracacao = retorno.valorAtracacaoDesatracacao;
                //EQUIPAMENTOS
                retorno.equipamentos.forEach(equipamento => {
                    var equipamentoGravar = new PropostaEquipamento();
                    equipamentoGravar.propostaId = this.item.id;
                    equipamentoGravar.equipamentoId = equipamento.equipamentoId;
                    equipamentoGravar.equipamento = equipamento.equipamento;
                    equipamentoGravar.tempoMinimo = equipamento.tempoMinimo;
                    equipamentoGravar.porcentagemExcedente = equipamento.porcentagemExcedente;
                    equipamentoGravar.valor = equipamento.valor;
                    equipamentoGravar.periodoId = equipamento.periodoId;
                    equipamentoGravar.periodo = equipamento.periodo;
                    this.item.equipamentos.push(equipamentoGravar);
                });

                this.dialogCadastro = true;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }

    AbrirDialogCadastro(item?: Proposta){
        if(item){
            this.overlay = true;
            this.service.ObterPorId(item.id, "Cliente, Contato, Responsavel, Ternos, Equipamentos.Equipamento.Categoria, Equipamentos.Periodo, Porto.LocaisOperacao, Porto, LocalOperacao.Periodos, LocalOperacaoPeriodos.Periodo").then(
                res=>{
                    this.item = new Proposta(res.data);
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.overlay = false;
            })
        }
        else{
            this.item = new Proposta();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Excluir(item: Proposta){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
